import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  TextField
} from "@mui/material";
import CookieJs from "js-cookie";
import { get } from "lodash";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import useNotification from "../../hooks/useNotification";
import service from "../../services/group";
import queryDefault from "../../utils/queryDefault";

export default function BranchGroupEditPage() {
  const { id } = useParams();
  const notification = useNotification();
  const navigate = useNavigate();
  const user = JSON.parse(CookieJs.get("USER") || "");
  const schema = yup.object().shape({
    name: yup.string().required("group name is a required field"),
  });
  const currentGroup = useQuery("view-group", () => service.getDetailBranchGroup(id));
  const form = useForm({
    defaultValues: {
      name: "",
      username: user.username,
      percentage: 0,
    },
    resolver: yupResolver(schema),
  });

  const createBranchGroup = useMutation("create-group", service.createBranchGroup, {
    onSuccess: (response) => {
      navigate("/app/branch-group/");

      notification.onOpen({
        message: response.message === "" ? "Group was successfully created!" : response.message,
        type: "success",
        position: "top",
      });
    },
    onError: (error: any) => {
      notification.onOpen({
        message: error?.response?.data?.message === undefined ? "Something went wrong" : error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const updateBranchGroup = useMutation("update-group", service.updateBranchGroup, {
    onSuccess: (response) => {
      navigate("/app/branch-group/");

      notification.onOpen({
        message: response.message === "" ? "Group was successfully updated!" : response.message,
        type: "success",
        position: "top",
      });
    },
    onError: (error: any) => {
      notification.onOpen({
        message: error?.response?.data?.message === undefined ? "Something went wrong" : error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const onSubmit = (values: any) => {
    if (id) {
      updateBranchGroup.mutate({
        data: {
          ...values,
          id: id
        }
      })
    } else {
      createBranchGroup.mutate({
        data: values,
      });
    }
  };

  useEffect(() => {
    if (id) {
      form.setValue("name", get(currentGroup, "data.data.name"));
      form.setValue("percentage", get(currentGroup, "data.data.name") ?? 0);
    }
  }, [currentGroup, form, id]);

  return (
    <MainLayout>
      <Breadcrumb
        label={id ? "Edit Branch Group" : "Create Branch Group"}
        breadcrumbs={[
          { label: "Group", href: "/app/branch-group" },
          {
            label: id ? "Edit Branch Group" : "Create Branch Group",
            href: "/app/branch-group/create",
          },
        ]}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 2]}>
        {!currentGroup.isLoading ? (
          <Box sx={{ mt: 2 }}>
            <Grid container>
              <Grid item lg={4} xs={12}>
                <FormControl fullWidth margin="normal">
                  <FormLabel required>Group Name</FormLabel>
                  <Controller
                    name="name"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <FormLabel required>Percentage</FormLabel>
                  <Controller
                    name="percentage"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>) : <></>}
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              loading={createBranchGroup.isLoading}
            >
              SAVE
            </LoadingButton>

            <Button variant="outlined" onClick={() => navigate(-1)}>
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
